.CreditsItems {
    background-color: #4f0404;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.credits-header {
    text-align: center;
    font-size: 3rem;
    margin-top: 3rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 1rem;
}

.credit-list,
.author {
    text-align: center;
    font-size: 2rem;
    color: white;
    margin-top: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
}