.P5RS-items {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.p5r-header {
    text-align: center;
    font-size: 3rem;
    margin-top: 3rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 1rem;
}

.p5r-video-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.2;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.p5r-video-bg video {
    width: 100%;
    height: 100%;
}

.char-list-p5r {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 30px;
    list-style: none;
    justify-content: center;
    margin-top: 5rem;
}

.char-links {
    text-decoration: none;
    background-color: black;
}

.char-links:hover {
    filter: brightness(50%);
    transition: all 0.2s ease-out
}

@media screen and (max-width: 960px) {
    .P5RS-items {
        height: auto;
    }
    .char-list-p5r {
        grid-template-columns: none;
        margin-bottom: 3rem;
    }
    .p5r-video-bg {
        opacity: 0%;
    }
}