.HomeItems {
    background-color: #4f0404;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
    opacity: 0.1;
    z-index: -1;
}

.video-bg video {
    width: 100%;
    height: 100%;
    transition: 2s ease-in-out;
}

.home-header {
    text-align: center;
    font-size: 3rem;
    margin-top: 3rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 1rem;
}

.home-logos {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 100px;
    justify-content: center;
    text-align: center;
    margin-top: 8%;
    top: 50%;
}

.p5-logo {
    width: 31rem;
}

.p5-logo:hover {
    transition: transform .2s;
    transform: scale(1.2);
}

.p5r-logo {
    width: 35rem;
}

.p5r-logo:hover {
    transition: transform .2s;
    transform: scale(1.2);
}

@media screen and (max-width: 960px) {
    .home-logos {
        display: grid;
        grid-template-columns: none;
        grid-gap: 120px;
        justify-content: center;
        text-align: center;
        margin-top: 6rem;
        margin-left: 2rem;
        margin-right: 2rem;
        top: 50%
    }
    .video-bg {
        min-width: none;
        max-width: 100%;
        min-height: auto;
        max-height: none;
    }
    .video-bg video {
        width: auto;
        height: auto;
    }
    .p5-logo {
        width: 19rem;
    }
    .p5r-logo {
        width: 21rem;
    }
}